<template lang="pug">
  .ticker-tape.text-23.md-text-33.w-full.overflow-x-hidden
    .nowrap(style="width: 100000px")
      .ticker-tape__body.inline-block(:class="{'ticker-tape__body--animate': animate}", :style="{animationDuration: 120 * texts.length + 's'}")
        template(v-for="n in 12")
          template(v-for="(item, i) in texts", v-if="item.text")
            prismic-rich-text.inline-block.children-inline(:field="item.text")
            //- space between items
            template(v-for="n in 10") &nbsp;
</template>

<script>
export default {
  name: 'Ticker',
  props: {
    texts: { type: Array, default: () => [] },
    animate: { type: Boolean, default: true }
  }
}
</script>

<style>
.ticker-tape__body{
  /* animation duration in style */
  animation: ticker infinite linear;
  animation-play-state: paused;
}
.ticker-tape__body--animate{
  animation-play-state: running;
}
@keyframes ticker {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-50%);
  }
}
</style>
