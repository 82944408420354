import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrismicVue from 'prismic-vue'
import linkResolver from './plugins/prismic/link-resolver'
import htmlSerializer from './plugins/prismic/html-serializer'
import VueScrollTo from 'vue-scrollto'
import VueMeta from 'vue-meta'
import './plugins/element-ui.js' // load Element UI components
import '@lottiefiles/lottie-player'

Vue.use(PrismicVue, {
  endpoint: `https://${process.env.VUE_APP_PRISMIC_REPO}.cdn.prismic.io/api/v2`,
  linkResolver,
  htmlSerializer,
})

Vue.use(VueMeta)
Vue.use(VueScrollTo, { duration: 800 })

// Global directive to observe element visibility
// <div v-intersects="0.1" @visible, @hidden>
Vue.directive('intersects', {
  inserted: function (el, binding, vnode) {
    const threshold = binding.value || 0.5 // v-observe="0.1"
    const onIntersection = entries => {
      const eventName = entries[0].isIntersecting ? 'intersects' : 'outersects'
      // emit...
      if (vnode.componentInstance) {
        // component ?
        vnode.componentInstance.$emit(eventName) // , {detail: eventDetail}); // use {detail:} to be uniform
      } else {
        // vanilla DOM element
        vnode.elm.dispatchEvent(new CustomEvent(eventName)) // , {detail: eventDetail}));
      }
    }
    // observe!
    el.observer = new IntersectionObserver(onIntersection, { threshold })
    el.observer.observe(el)
  }
  // unbind: function (el) {
  //   el.observer.unobserve(el)
  // }
})

Vue.config.ignoredElements = [/^fontdue-/]

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
