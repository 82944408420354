<template lang="pug">
.fixed.overlay.pointer-events-none(v-show="qty > 0", :style="style", :class="{'opacity-0': !blur}")
</template>

<script>
import { mapState } from "vuex";
// import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
const maxBlur = 22;
export default {
  name: "BlurLayer",
  props: ["blur"],
  data() {
    return {
      qty: maxBlur,
      infoH: 0
    };
  },
  computed: {
    ...mapState(["winH", "intro"]),
    style() {
      return {
        "backdrop-filter": `blur(${this.qty}px)`,
        "-webkit-backdrop-filter": `blur(${this.qty}px)`,
        transition: this.intro
          ? "opacity 500ms"
          : this.blur
          ? "opacity 200ms"
          : "opacity 200ms"
      };
    },
    trigger() {
      // info scroll Pt at which to start animating blur qty
      // should match minimum of: #info-end height + winH
      return this.infoH - this.winH * 2.3;
    }
  },
  methods: {
    bindBlurLayerToOverlayScroll(el) {
      // bind
      setTimeout(() => {
        this.infoH = el.scrollHeight;
        // disableBodyScroll(el);
        el.addEventListener("scroll", this.onOverlayScroll);
      }, 700);
    },
    unbindBlurLayerToOverlayScroll(el) {
      el.removeEventListener("scroll", this.onOverlayScroll);
      // clearAllBodyScrollLocks();
    },
    onOverlayScroll(e) {
      /**
       * When overlay el is scrolling, hide the blur-layer el completely
       * via qty=0 if it's not visible (improves scroll smoothness)
       **/
      const y = e.target.scrollTop;
      // no blur zone
      if (y < this.trigger) {
        if (!this.qty) return;
        this.qty = 0;
        // change blur
      } else {
        const pct = (y - this.trigger) / this.winH;
        const val = maxBlur - maxBlur * pct;
        if (val < 0) return;
        requestAnimationFrame(() => {
          this.qty = val;
        });
      }
    }
  },
  watch: {
    blur(blur) {
      if (blur) this.qty = maxBlur;
    }
  },
  mounted() {
    this.$root.$on(
      "bindBlurLayerToOverlayScroll",
      this.bindBlurLayerToOverlayScroll
    );
    this.$root.$on(
      "unbindBlurLayerToOverlayScroll",
      this.unbindBlurLayerToOverlayScroll
    );
  }
};
</script>

<style></style>
