<template lang="pug">
  .opacity-0.pointer-events-none.fixed.w-full(v-if="render", style="top:100%; left:100%; z-index: -1")
    //- preload first section of all top-level `project_feed` docs in MENU
    //- for better page transitions
    template(v-for="item in menu", v-if="item.link && item.link.type === 'project_feed'")
      project-body(:doc="prj(item.link.uid)", :preload="1", :index="0")
</template>

<script>
import { mapState } from "vuex";
import ProjectBody from "@/components/ProjectBody";
import _get from "lodash/get";
export default {
  name: "Preload",
  components: { ProjectBody },
  computed: {
    ...mapState(["main", "feeds", "projects", "intro", "preload"]),
    menu() {
      return this.main && this.main.menu;
    },
    render() {
      return this.menu && !this.intro && this.preload;
    }
  },
  methods: {
    prj(uid) {
      const feed = this.feeds.find(feed => feed.uid === uid);
      const prjId = _get(feed, "data.body[0].primary.project.id");
      return this.projects.find(prj => prj.id === prjId);
    }
  },
  watch: {
    preload() {
      if (!this.preload) {
        // destroy component after removed from DOM
        this.$nextTick(() => this.$destroy());
      }
    }
  }
};
</script>
