<template lang="pug">
.relative
  .fixed.right-0.bottom-0.z-50.flex.md-hidden.md-items-center.p-15.md-p-20.pointer-events-none
    hover-link-image(v-for="(item, i) in items" :key="i + items.length", v-show="i === activeIndex", :item="item", :width="290")
  .fixed.right-0.bottom-0.z-50.h-full.md-flex.hidden.md-items-center.p-15.md-p-20.pointer-events-none
    hover-link-image(v-for="(item, i) in items" :key="i + items.length", v-show="i === activeIndex", :item="item", :width="400")

  h1.uppercase.pb-em {{title}}
  ul.flex.flex-col
    li.inline-flex(v-for="(item, i) in items" :key="i")
      hover-link(
        ref="link",
        :text="item.text", 
        :index="i", 
        :scrollTop="scrollTop", 
        :parentOffset="offsetTop" 
        :active="i === activeIndex"
        :link="item.project_link"
        :hoverColor="hoverColor"
        @mouseenter="activeIndex = i"
        @mouseleave="activeIndex = null"
      )
</template>
<script>
import Observer from "@/components/IntersectionObserver";
import HoverLink from "./HoverLink.vue";
import RespImg from "../../components/RespImg.vue";
import HoverLinkImage from "./HoverLinkImage.vue";
export default {
  props: ["title", "items", "hoverColor"],
  data() {
    return {
      activeIndex: null,
      scrollTop: 0,
      offsetTop: 0
    };
  },
  mounted() {
    this.wrapper.addEventListener("scroll", this.onScroll);
    this.onScroll();
  },
  beforeUnmount() {
    this.wrapper.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.scrollTop = this.wrapper.scrollTop;
      this.offsetTop = this.$el.offsetTop;

      if (this.scrollTop + window.innerHeight * 0.5 < this.offsetTop) {
        this.activeIndex = -1;
        return;
      }

      if (
        this.scrollTop > this.offsetTop - window.innerHeight &&
        this.scrollTop < this.offsets[this.offsets.length - 1]
      ) {
        this.activeIndex = this.offsets.indexOf(this.closest);
      } else {
        this.activeIndex = -1;
      }
    }
  },
  computed: {
    wrapper() {
      return document.getElementById("info");
    },
    offsets() {
      return this.$refs.link.map(link => link.offsetTop);
    },
    closest() {
      const scrollTop = this.scrollTop;
      return this.offsets.reduce(function(prev, curr) {
        return Math.abs(curr - scrollTop) < Math.abs(prev - scrollTop)
          ? curr
          : prev;
      });
    }
  },
  components: {
    Observer,
    HoverLink,
    RespImg,
    HoverLinkImage
  }
};
</script>
