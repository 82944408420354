<template lang="pug">
component(:is="introAnimComponent", :settings="settings", @cardHt="$event => { cardHt = $event }", @showInnerContent="innerContentVisible = true", @innerContentOpacity="$event => { innerContentOpacity = $event }")

  //- slot content from prismic:

  //- inner body (set height so rollup via max-height doesn't move inner content up)
  .absolute.top-0.left-0.w-full(:style="{height: cardHt}")

    //- (card type: text)
    template(v-if="introCardSlice.slice_type === 'intro_card__text'")
      //- fill card, vertically center content
      .absolute.overlay.flex.items-center.justify-center.w-full.text-center
        .intro-card__tagline.pl-10.pr-85.md-px-20.text-24.lg-text-44.xl-text-48.text-black(:style="{'opacity': innerContentOpacity, paddingTop: '4rem', color: settings.textColor}", :class="{'intro-card__tagline--invisible': !innerContentVisible}")
          //- title
          .intro-card__tagline__title {{ $prismic.richTextAsPlain(introCardSlice.primary.title1) }}
          //- subtitle
          .intro-card__tagline__subtitle {{ $prismic.richTextAsPlain(introCardSlice.primary.subtitle) }}

    //- (card type: svg graphic)
    template(v-if="introCardSlice.slice_type === 'intro_card__svg'")
      //- padding inside element so maxHeight animation works properly
      //- using .flex so children are full height
      .absolute.overlay.px-15.py-20.flex
        //- padded frame
        .w-full.relative
          //- (has graphic?)
          template(v-if="introCardSlice.primary.svg_graphic")
            //- detects when graphic is loaded
            img.hidden(:src="introCardSlice.primary.svg_graphic.url", @load="svgLoaded = true")
            //- (svg graphic fade in when loaded)
            transition(name="fade")
              //- bg positioning from cms
              .absolute.overlay.bg-no-repeat(v-if="svgLoaded", :style="`background-image: url(${introCardSlice.primary.svg_graphic.url}); ` + introCardSlice.primary.svg_graphic_custom_css")

</template>

<script>
import { mapState } from "vuex";
import IntroCardAnimBST from "@/components/IntroCardAnimBST";
import IntroCardAnimSF from "@/components/IntroCardAnimSF";
export default {
  name: "IntroCard",
  computed: {
    ...mapState({ settings: "introSettings" }),

    introAnimComponent() {
      return this.settings.type === "sf" ? IntroCardAnimSF : IntroCardAnimBST; // bst
    },
    introCardSlice() {
      return this.$store.state.main?.intro_card_slices[0] || {};
    }
  },
  data() {
    return {
      cardHt: "auto",
      innerContentVisible: false,
      innerContentOpacity: 1,
      svgLoaded: false
    };
  }
};
</script>

<style lang="postcss">
.intro-card__tagline {
  & .intro-card__tagline__title {
    transition: opacity 700ms;
  }
  & .intro-card__tagline__subtitle {
    transition: opacity 700ms 700ms;
  }
  &.intro-card__tagline--invisible {
    & .intro-card__tagline__title,
    & .intro-card__tagline__subtitle {
      opacity: 0;
    }
  }
}
</style>
