<template>
  <div :style="{ background }" class="w-full h-full leading-0">
    <swiper v-if="loaded" :loop="true" :looped-slides="items.length" :speed="500" slides-per-view="auto" :centered-slides="true" :space-between="0" :grab-cursor="true" @swiper="onSwiper" :initial-slide="0" @slideChange="slideChange" @touchStart="touchStart" @setTranslate="onDrag" @touchEnd="touchEnd" class="swiper-wrapper">
      <swiper-slide v-for="(item, index) in items" :key="item.key" class="relative w-auto">
        <resp-img :src="getSrc(item)" :style="getStyle(item, index)" :byHeight="true" draggable="false" class="overlay object-cover"/>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  import RespImg from '../../components/RespImg.vue'
  import { Swiper, SwiperSlide } from 'swiper-vue2'

  export default {
    name: 'CarouselThumbs',
    props: ['items', 'currentSlide', 'instantMode', 'background', 'visible', 'parentActive'],
    data () {
      return {
        dragging: false,
        loaded: false,
        swiper: null,
        thumbBreaks: [],
        transformDuration: 600,
        padding: 16
      }
    },
    watch: {
      currentSlide (val) {
        if (!this.dragging) this.swiper?.slideTo(val)
      }
    },
    methods: {
      onSwiper (swiper) {
        console.log('thumbs', swiper.activeIndex)
        this.swiper = swiper
      },
      slideChange ({ activeIndex }) {
        // console.log(s)
        console.log('changed', activeIndex, this.currentSlide)
      },
      touchStart () {
        this.dragging = true
      },
      onDrag ({ progress, loopedSlides }) {
        if (!this.dragging) return

        const track = loopedSlides * 3
        const activeSlide = Math.round(progress * track)

        this.$emit('scroll-change', activeSlide)
      },
      touchEnd () {
        this.dragging = false
      },
      getSrc (item) {
        if (item.media?.url) return item.media.url
        else if (item.video) {
          return process.env.VUE_APP_CLOUDINARY_URL + `/video/upload/${item.video}.jpg`
        }
        return ''
      },
      getStyle (item, index) {
        return {
          width: this.measureThumb(item) + "px",
          transition: `margin ${this.transformDuration * 0.5}ms ease-in-out`,
          margin: this.isSlideActive(index) && !this.dragging
              ? "0 " + this.padding + "px"
              : 0
        }
      },
      measureThumbs () {
        let totalWidth = 0;
        this.items.forEach(item => {
          const thumbWidth = this.measureThumb(item);
          this.thumbBreaks.push(Math.round(totalWidth + thumbWidth / 2));
          totalWidth += thumbWidth;
        });
        return totalWidth;
      },
      measureThumb (item) {
        if (!this.$el) return 80

        const wrapperHeight = this.$el.offsetHeight
        if (!item || !item.media || !item.media.url) return wrapperHeight

        const { width, height } = item.media
        const ratio = width / height
        const thumbWidth = ratio * wrapperHeight
        return thumbWidth
      },
      isSlideActive (index) {
        return this.currentSlide === index
      },
      init () {
        this.measureThumbs()
        this.loaded = true
      }
    },
    mounted () {
      this.init()
    },
    beforeUnmount () {
    },
    computed: {
      leavingIntro () { return this.$store.state.leavingIntro }
    },
    components: { RespImg, Swiper, SwiperSlide }
  }
</script>
