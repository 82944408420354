<template lang="pug">
.slice--text
  prismic-rich-text.p-15.md-p-20.bg-black.text-white(:field="slice.primary.text", :style="{color: slice.primary.text_color, backgroundColor: slice.primary.bg_color}", :class="[fontSize]")
</template>

<script>
export default {
  name: "Slice--Text",
  props: ["slice"],
  computed: {
    fontSize() {
      const sz = this.slice.primary.font_size || "medium";
      return {
        "text-14 md-text-18": sz === "small",
        "text-20 md-text-24": sz === "medium",
        "text-24 md-text-33": sz === "large",
        "text-24 md-text-40": sz === "very-large",
        "text-24 md-text-48": sz === "display"
      };
    }
  }
};
</script>
