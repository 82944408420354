<template>
  <lottie-player background="transparent" autoplay loop/>
</template>

<script>
  export default {
    props: {
      data: String
    },
    computed: {},
    mounted () {
      const player = this.$el
      player.addEventListener('rendered', () => {
        player.load(this.data)
      })
    }
  }
</script>
