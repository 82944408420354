<template>
  <div class="mx-15 md-mx-20 -mt-px">
    <div class="border-t border-b border-dark">
      <div v-if="!isLink">
        <div @click="isOpen = !isOpen" class="py-20 md-py-30 flex justify-between cursor-pointer items-center">
          <h2 class="text-21 md-text-24">{{ summary }}</h2>
          <div :class="{ '-rotate-45': isOpen }" class="transition-all duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.205 17.205"><g id="Group_2158" data-name="Group 2158" transform="translate(-0.043)"><g id="Group_2166" data-name="Group 2166"><line id="Line_3" data-name="Line 3" y2="17.205" transform="translate(8.646 0)" fill="none" stroke="currentColor" stroke-width="2"/><line id="Line_4" data-name="Line 4" x2="17.205" transform="translate(0.043 8.602)" fill="none" stroke="currentColor" stroke-width="2"/></g></g></svg>
          </div>
        </div>

        <transition @enter="slideOpen" @leave="slideClose">
          <div v-if="isOpen" class="overflow-hidden">
            <prismic-rich-text :field="details" class="text-21 md-text-24 pb-20 md-pb-30" />
          </div>
        </transition>
      </div>

      <div v-else>
        <a :href="link" target="_blank" rel="noopener" class="py-20 md-py-30 text-21 md-text-24 flex justify-between items-center">
          <span>{{ summary }}</span>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.304 17.202"><g id="Group_2168" data-name="Group 2168" transform="translate(-712 -872.603)"><path id="Path_867" data-name="Path 867" d="M.668,82.219l7.955,7.147,8.016-7.136" transform="translate(712 795)" fill="none" stroke="currentColor" stroke-width="2"/><line id="Line_7" data-name="Line 7" x2="17.292" transform="translate(712.008 888.805)" fill="none" stroke="currentColor" stroke-width="2"/><line id="Line_8" data-name="Line 8" y2="10.806" transform="translate(720.654 872.603)" fill="none" stroke="currentColor" stroke-width="2"/></g></svg>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SliceDetails',
    props: ["slice"],
    data () {
      return {
        isOpen: false
      }
    },
    methods: {
      slideOpen,
      slideClose
    },
    computed: {
      summary () {
        return this.slice?.primary.summary
      },
      details () {
        return this.slice?.primary.details
      },
      isLink () {
        return this.slice?.primary.is_link
      },
      link () {
        return this.slice?.primary.link?.url
      }
    }
  }

  export function slideOpen (el, done) {
    const duration = 250
    const h = el.getBoundingClientRect().height
    const height = `${h}px`

    const fade = el.getAttribute('data-fade') !== 'false'

    const hideOverflow = el.getAttribute('data-overflow')
    if (hideOverflow) {
      el.classList.add('overflow-hidden')
    }

    const animation = el.animate({
      height: ['0px', height],
      opacity: [fade ? 0 : 1, 1]
    }, { duration,  easing: 'ease-out' })

    animation.onfinish = () => {
      if (hideOverflow) {
        el.classList.remove('overflow-hidden')
      }
      done()
    }
  }
  export function slideClose (el, done) {
    const duration = 250
    const h = el.getBoundingClientRect().height
    const height = `${h}px`

    const fade = el.getAttribute('data-fade') !== 'false'
    const hideOverflow = el.getAttribute('data-overflow')
    if (hideOverflow) {
      el.classList.add('overflow-hidden')
    }

    const animation = el.animate({
      height: [height, '0px'],
      opacity: [1, fade ? 0 : 1]
    }, { duration, easing: 'ease-out' })

    animation.onfinish = () => {
      if (hideOverflow) {
        el.classList.remove('overflow-hidden')
      }
      done()
    }
  }
</script>

<style>
  .-rotate-45 {
    transform: rotate(-45deg);
  }
</style>
