<script>
import LabeledLink from "@/components/LabeledLink";
export default {
  props: ["slice"],
  components: { LabeledLink },
  mounted() {
    // Find closest data-text_color
    const el = this.$el.closest("[data-text-color]");
  },
  computed: {
    color() {
      return this.slice.primary.text_color || "black";
    },
    fontSize() {
      const sz = this.slice.primary.font_size || "medium";
      return {
        "text-14 md-text-18": sz === "small",
        "text-20 md-text-24": sz === "medium",
        "text-24 md-text-33": sz === "large",
        "text-24 md-text-40": sz === "very-large",
        "text-24 md-text-48": sz === "display"
      };
    }
  }
};
</script>

<template lang="pug">
.slice--text(:class="[fontSize]")
  ul.p-15.md-p-20.list-reset
    li(v-for="item in slice.items")
      labeled-link(:label="item.link_label", :link="item.link")
</template>
