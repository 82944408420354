<template>
  <div class="h-0"></div>
</template>

<script>
  export default {
    name: 'SliceFontFamily',
    props: ["slice"],
    data () {
      return {}
    },
    computed: {
      familyName () {
        return this.slice?.primary.font_title || ''
      },
      familyId () {
        return this.slice?.primary.slug
      },
      fontdueLoaded () {
        return this.$store.state.fontdueLoaded
      }
    },
    methods: {
      init () {
        this.$store.commit('FONTDUE_ID', this.familyId)
      }
    },
    watch: {
      fontdueLoaded (val) {
        if (!val) return

        this.init()
      }
    },
    mounted () {
      if (!this.fontdueLoaded) return

      this.init()
    },
    destroyed () {
      this.$store.commit('FONTDUE_ID_RM')
    }
  }
</script>
