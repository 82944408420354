<script>
import Cross from "./SVG/Cross.vue";
import Menu from "./SVG/Menu.vue";
export default {
  components: {
    Cross,
    Menu
  },
  methods: {
    onClick() {
      this.$store.commit("PROJECT_MENU_INACTIVE");
    },
    closeProject() {
      // go to parent route
      const path = this.$route.path.split("/");
      const parentPath = path.slice(0, path.length - 1).join("/");
      // go
      return this.$router.push(parentPath);
    }
  }
};
</script>

<template>
  <div
    class="flex px-20 leading-none rounded-lg "
    :style="{
      width: 'fit-content',
      margin: 'auto'
    }"
  >
    <div class="flex items-center" :style="{ gap: '30px' }">
      <div @click="onClick" :style="{ width: '2.4rem' }" class="cursor-pointer">
        <Menu />
      </div>
      <div class="w-20 cursor-pointer" @click="closeProject">
        <Cross />
      </div>
    </div>
  </div>
</template>
