<template lang="pug">
.slice--two-column-text.text-24.md-text-33.p-15.md-p-20
  .mb-15(v-if="slice.primary.section_title") {{ slice.primary.section_title }}
  .md-grid.grid-cols-2
    .w-full
      prismic-rich-text.info-view__text.children-mt-em.tooltip-hashlinks(:field="slice.primary.left_column")
    .w-full
      prismic-rich-text.mt-20px.lg-mt-0.lg-ml-20px.info-view__text.children-mt-em.tooltip-hashlinks(:field="slice.primary.right_column")
</template>

<script>
  export default {
    props: ["slice"]
  }
</script>
