<template>
  <div v-if="fontdueLoaded" :style="styles" class="p-15 md-p-20 overflow-hidden">
    <h2 class="text-33 font-medium">{{ title }}</h2>
    <fontdue-type-testers :collection-slug="slug" class="mt-8"/>
  </div>
</template>

<script>
  export default {
    name: 'SliceTypeTester',
    props: ["slice"],
    computed: {
      slug () {
        return this.slice?.primary.collection_slug
      },
      fontdueLoaded () {
        return this.$store.state.fontdueLoaded
      },
      title () {
        return this.slice.primary.title1 || 'Type Tester'
      },
      styles () {
        return {
          backgroundColor: this.slice.primary.bg_color1 || 'black',
          color: this.slice.primary.color || 'white'
        }
      }
    }
  }
</script>
