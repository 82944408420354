<template lang="pug">
  form(@submit.prevent="submit")
    .flex(v-show="status !== 200")
      label.reader-show Subscribe
      input.block.flex-1(type="email", name="email", :placeholder="placeholder", v-model="formData.email", required, @focus="placeholder = 'Email'", @blur="placeholder = 'Subscribe'")
      button.pl-10(type="submit", aria-label="Submit", :class="{'hidden': !formData.email.length}") &ldsh;
    //- response
    template(v-if="status")
      //- success
      .cursor-pointer(v-if="status === 200", @click="reset", aria-label="Reset Form") Thank You
      //- error: sever
      .mt-10(v-else-if="status === 500")
        | Error. Please email<br>
        | <a href="mailto:contact@britishstandardtype.xyz" target="_blank" rel="noopener">contact@britishstandardtype.xyz</a>
      //- error
      .mt-10(v-else) Please try again.
</template>

<script>
export default {
  name: 'SubscribeForm',
  data () {
    return {
      formData: {
        email: ''
      },
      submitting: false,
      status: null,
      placeholder: 'Subscribe'
    }
  },
  methods: {
    submit () {
      if (this.submitting) return
      this.status = null
      this.submitting = true
      // GO !
      fetch('/.netlify/functions/campaign-monitor__add-subscriber', {
        method: 'POST',
        body: JSON.stringify(this.formData)
      })
        .then(resp => resp.json())
        .then(data => {
          this.status = data.status
          this.submitting = false
          console.log(data)
        })
        .catch(err => {
          console.error(err)
          this.status = 500
          this.submitting = false
        })
    },
    reset () {
      this.status = null
      this.formData.email = ''
    }
  }
}
</script>

<style scoped>
input[type="email"]{
  &:focus::placeholder{
    color: inherit;
    opacity: 0.1;
  }
}
</style>
