<template lang="pug">
.fixed.w-full.h-full.top-0.left-0.pointer-events-none.flex.p-15.md-p-20(v-if="visible", :style="{height: winH + 'px'}")
  //- frame, padded from parent
  .w-full.overflow-hidden.relative

    //- header bar height calculator
    .header-row.relative(ref="row")

    //- wrapper / white card (rolls-up with scroll)
    .absolute.top-0.left-0.w-full.overflow-hidden.flex.pointer-events-auto(ref="wrapper", :style="wrapperStyle", @click="onClick")
      //- content from prismic
      slot
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "IntroCardAnimBST",
  props: ["settings"],

  // init
  mounted() {
    this.setupIntro();
  },

  // run intro once site loading is complete (main image content loads)
  watch: {
    loading (loading) {
      if (!loading) this.runIntro();
    }
  },

  data() {
    return {
      visible: true,
      // expanded: true,
      cardHt: "auto",
      rowH: 0,
      initH: 0,
      maxH: 10000,
      mainEl: null,
      measured: false
    };
  },

  computed: {
    ...mapState(["intro", "main", "loading", "winW", "winH"]),
    ...mapGetters(["isPortrait", "header"]),
    introCardSlice() {
      return this.main?.intro_card_slices[0] || {};
    },
    introEnabled() {
      return !this.isPortrait; // && process.env.VUE_APP_INTRO_CARD
    },
    wrapperStyle() {
      return {
        height: this.cardHt,
        maxHeight: `${this.maxH}px`,
        background: this.settings.bgColor,
        cursor: !this.settings.autoCollapse ? "s-resize" : "default"
      };
    }
  },

  methods: {
    setupIntro() {
      if (!this.loading) return;

      // hide app body content
      this.mainEl = document.querySelector("#app__main");
      this.mainEl.style.opacity = 0;

      if (
        this.$route.params.uid === "information-feed" ||
        this.$route.params.uid === "information"
      ) {
        this.$store.commit("BLUR_OFF");
        this.$store.commit("END_LOADING");
        return;
      }
      // skip on portrait devices
      if (!this.introEnabled) {
        // no blur of main content
        this.$store.commit("BLUR_OFF");
        // un-render intro card altogether
        this.visible = false;
        // exit setup but main body conten fades in after loading is done, in runIntro()
        return;
      }

      // set initial card height based on screen
      const bottomGap = this.settings.fullscreen ? "0rem" : "4rem";
      this.cardHt = `calc(${this.winH}px - ${bottomGap})`;
      this.$emit("cardHt", this.cardHt);
    },

    async runIntro() {
      console.log('runIntro()', this.intro)
      if (!this.intro) return;

      // timing helper
      const wait = time =>
        new Promise(resolve => setTimeout(() => resolve(), time));

      // begin!

      // 1. background/main content fades in (500)
      this.mainEl.style.transition = "opacity 500ms"; // , filter 500ms';
      this.mainEl.style.opacity = 1;

      console.log('run, fade in main el?')

      await wait(600);

      // after fade-in...

      // reset styles
      this.mainEl.style.transition = "";
      this.mainEl.style.opacity = "";

      // SKIP INTRO SEQUENCE ??? ==========================

      if (!this.introEnabled) return this.skipIntro();

      // SKIP INTRO SEQUENCE ??? ==========================

      // 2. reveal inner content
      this.$emit("showInnerContent");

      // 3. card "shrinks" ? (optional)

      // settings:
      // - allows testing via route query params
      const dur = this.$route.query.duration || 700; // ms
      const easing = this.$route.query.easing || "0,0.55,0.45,1";
      this.$refs.wrapper.style.transition = `transform ${dur}ms cubic-bezier(${easing})`;

      // wait a little...
      await wait(dur);

      // shrink!
      // (card "shrinks" by translating up inside overflow-hidden frame)
      this.$refs.wrapper.style.transform = "translateY(-6rem)";

      // wait a little...
      await wait(dur);

      // 4. unblur body content...
      this.$store.commit("BLUR_OFF");

      // wait for blur (if not fullscreen)
      await wait(600);

      // 5. card closes with scroll (by user or triggered immediately)

      // unlock scroll
      this.$store.commit("END_INTRO");

      // setup scroll
      this.measure();
      this.measured = true;

      // bind
      window.addEventListener("scroll", this.onScroll);
    },

    skipIntro() {
      this.$store.commit("TITLE_INACTIVE");
      this.$store.commit("END_INTRO");
      this.$destroy();
    },

    onScroll() {
      const h = this.initH - window.pageYOffset;

      // end when header height (bst) or top of screen? (sf)
      const endHeight =
        this.header?.slice_type === "site_header__bar"
          ? this.rowH + 60 // bst
          : 0; // sf

      // once card height is row height, remove scroller
      if (h <= endHeight) {
        this.visible = false;
        window.removeEventListener("scroll", this.onScroll);
      }

      // animate with scroll
      requestAnimationFrame(() => {
        // "rollup" card
        this.maxH = h;
        // fade out inner text
        const innerContentOpacity =
          1 - Math.min(1, window.pageYOffset / parseInt(this.initH * 0.66));
        this.$emit("innerContentOpacity", innerContentOpacity);
      });
    },

    onClick() {
      if (!this.measured) return;

      this.collapse();
    },

    collapse() {
      // this.expanded = false
      // setTimeout(() => { this.visible = false }, 500) // after collapse anim
      // setTimeout(() => this.$store.commit('END_INTRO'), 500) // after anim

      this.$scrollTo(".landing-slice__content", { duration: 600 });
    },

    measure() {
      this.rowH = this.$refs.row.offsetHeight;
      this.initH = this.maxH = this.$refs.wrapper.offsetHeight;
    }
  }
};
</script>
