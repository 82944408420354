<template>
  <div>
    <transition name="fade">
      <div v-if="familyId">
        <fontdue-buy-button :collection-slug="familyId" collection-name="" class="buy-btn fixed z-50"/>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    computed: {
      familyId () {
        return this.$store.state.fontdueId
      },
      fontdueLoaded () {
        return this.$store.state.fontdueLoaded
      }
    },
    mounted () {
      if (this.fontdueLoaded) return

      const s = document.createElement('script')
      s.src = 'https://js.fontdue.com/fontdue.js'
      s.type = 'text/javascript'
      s.addEventListener('load', () => {
        fontdue.initialize({
          url: process.env.VUE_APP_FONTDUE_STORE,
          config: {
            typeTester: {
              selectable: true,
              selectButton: false,
              initialAlignment: 'center',
              openTypeFeatures: {
                interactionStyle: 'panel'
              },
              size: {
                min: 24,
                max: 500
              }
            }
          }
        })
        this.$store.commit('FONTDUE_LOAD')
      })
      document.body.appendChild(s)
    }
  }
</script>
