<template lang="pug">
li.flex.hover-link(:class="{'hover-link--active': active}", :style="hoverStyle" @mouseenter="$emit('mouseenter')", @mouseleave="$emit('mouseleave')")
  span.w-em.mr-20 {{index + 1}}
  prismic-rich-text.text--custom(:field="text")
</template>

<script>
export default {
  props: [
    "index",
    "scrollTop",
    "active",
    "parentOffset",
    "text",
    "hoverColor",
    "link"
  ],
  data() {
    return {
      offsetTop: 0
    };
  },
  watch: {
    scrollTop() {
      this.onResize();
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  computed: {
    hoverStyle() {
      return {
        "--color-hover": this.hoverColor
      };
    }
  },
  methods: {
    onResize() {
      this.offsetTop =
        this.$el.offsetTop + this.parentOffset - window.innerHeight * 0.5;
    }
  }
};
</script>

<style>
.hover-link--active {
  color: var(--color-hover);
}
.hover-link--active a {
  color: var(--color-hover);
}
</style>
