<template lang="pug">
  .slice--full-b.relative
    //- foreground layer (sticky)
    figure.absolute.h-full.w-full.top-0.left-0.z-10(v-if="slice.primary.sticky_svg.url")
      //- sticky body
      .sticky.w-full.top-0.left-0.h-screen
        //- ? animating svg
        template(v-if="slice.primary.sticky_svg_animation_frame_rate")
          svg-animation(:svgUrl="slice.primary.sticky_svg.url", :frameRate="slice.primary.sticky_svg_animation_frame_rate", size="cover")
        //- ? static
        template(v-else)
          .absolute.overlay.bg-contain.bg-no-repeat.bg-center(:style="{backgroundImage: `url(${slice.primary.sticky_svg.url})`}", role="img", :aria-label="slice.primary.sticky_svg.alt")
    //- background (items)
    section(v-for="(item, i) in slice.items", :id="`prj${prjNo}__slice${sliceNo}__content`", :style="{backgroundColor: item.bg_color}", :class="itmClss(item)")
      //- ? lottie
      figure.relative.overflow-hidden.w-full.h-full(v-if="item.lottie_file")
        lottie-animation(:data="item.lottie_file")
      //- ? svg animation
      template(v-else-if="item.animation_frame_rate && item.bg_img.url")
        svg-animation.w-full(:svgUrl="item.bg_img.url", :frameRate="item.animation_frame_rate", :size="svgAnimSize(item.height)")
      //- ? screen height
      template(v-else-if="item.height === '1x screen'")
        .h-screen.bg-cover.bg-center.bg-no-repeat(:style="{backgroundImage: `url(${item.bg_img.url})`}", role="img", :aria-label="item.bg_img.alt")
      //- ? natural height
      template(v-else)
        img.block.w-full(:src="item.bg_img.url", :alt="item.bg_img.alt")
</template>

<script>
import svgAnimation from '../../components/SVGAnimation'
import LottieAnimation from '../../components/LottieAnimation'
export default {
  name: 'Slice--Full_B',
  props: ['slice', 'prjNo', 'sliceNo'],
  computed: {
    stickyHt () {
      return this.slice.primary.sticky_svg_height
    }
    // svgAnimSize () {
    //   return this.stickyHt === 'natural' ? 'w-auto' : 'cover'
    // }
  },
  methods: {
    itmClss (_) {
      return {
        'pb-200': _.pad_b === '200' && _.height === 'natural',
        'h-screen relative': _.height === '1x screen'
      }
    },
    svgAnimSize (field) {
      return field === 'natural' ? 'w-auto' : 'cover'
    }
  },
  components: { svgAnimation, LottieAnimation }
}
</script>
