<template lang="pug">
.labeled-link.inline-block(:class="{'labeled-link--can-hover': canHover}")
  span.text-current {{label}}
  prismic-rich-text.text-current(:field="link")
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LabeledLink",
  props: ["label", "link"],
  computed: {
    ...mapState(["canHover"])
  }
};
</script>

<style>
.labeled-link div {
  display: none;
}
.labeled-link.labeled-link--can-hover {
  & span {
    display: inline-block;
  }
  & div {
    display: none;
  }
  /* on hover */
  &:hover {
    & span {
      display: none;
    }
    & div {
      display: block;
    }
  }
}
</style>
