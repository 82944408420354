import Vue from 'vue'
import Router from 'vue-router'
import ProjectFeed from './views/ProjectFeed.vue'

const Project = () => import(/* webpackChunkName: "project" */ '@/views/Project.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: ProjectFeed,
      children: [
        // cannonical project route for direct loading / external linking
        // child of home so scrolling off reveals home
        {
          path: '/projects/:project',
          name: 'home__project',
          component: Project
        }
      ]
    },
    {
      path: '/projects',
      redirect: '/'
    },
    // preview
    {
      path: '/preview',
      name: 'preview',
      component: () => import(/* webpackChunkName: "preview" */ '@/views/Preview.vue')
    },
    // project feeds
    {
      path: '/:uid',
      name: 'feed',
      component: ProjectFeed,
      props: true,
      children: [
        // child of feed so scrolling off reveals feed
        {
          path: ':project',
          name: 'feed__project',
          component: Project
        }
      ]
    }
  ]
})
