<template lang="pug">
//- Updated to swiper carousel.
div
  .relative
    .swiper-container.relative.w-full.h-carousel(ref="container", v-intersects, @intersects="visible = true", @outersects="visible = false")
      swiper.swiper-wrapper(:loop="true", :looped-slides="items.length" :speed="500", slides-per-view="auto", :centered-slides="true", :space-between="0", :grab-cursor="true", @swiper="onSwiper", :initial-slide="0", @slideChange="slideChange", @touchStart="touchStart", @touchEnd="touchEnd", @transitionEnd="transitionEnd")
        swiper-slide.slide-mod(v-for='item in items', :key="item.key")
          .relative.h-full(:style="item.style")
            lottie-animation(v-if="item.lottie_file", :data="item.lottie_file")
            resp-bg-video(v-else-if="item.video", :id="item.video", :always="true")
            resp-img.absolute.overlay.top-0.left-0.object-cover(v-else, :src="item.media.url")

    button.hidden.md-block.absolute.z-10.top-0.left-0.w-1x6.cursor-pointer(@click.stop="prevSlide", aria-label="Previous Image", style="cursor:w-resize;height:92vh")
    button.hidden.md-block.absolute.z-10.top-0.right-0.w-1x6.cursor-pointer(@click.stop="nextSlide", aria-label="Next Image", style="cursor:e-resize;height:92vh")

  .sticky.bottom-0.w-full.z-10.overflow-hidden(:style="{ height: '8vh' }")
    carousel-thumbnails(:items="items", :currentSlide="currentSlide", :instantMode="instantMode", :parentActive="changeHere" :visible="visible" @scroll-change="onScrollChange", @scroll-start="onScrollStart" @moved="onMoved" :background="slice.primary.bg_color")
    //- carousel-thumbs(:items="items", :currentSlide="currentSlide", :instantMode="instantMode", :visible="visible" @scroll-change="onScrollChange", @scroll-start="onScrollStart" :background="slice.primary.bg_color")
</template>

<script>
import RespImg from "@/components/RespImg";
import CarouselThumbnails from "./CarouselThumbnails.vue";
import CarouselThumbs from "./CarouselThumbs.vue";
import LottieAnimation from "../../components/LottieAnimation.vue";
import RespBgVideo from "../../components/RespBgVideo.vue";
import { Swiper, SwiperSlide } from "swiper-vue2";

export default {
  name: "SliceCarousel",
  props: ["slice"],
  data() {
    return {
      currentSlide: 0,
      swiper: null,
      timer: null,
      visible: false,
      instantMode: false,
      changeHere: true
    };
  },
  computed: {
    items() {
      return this.slice.items?.map((item, idx) => {
        return {
          key: idx,
          style: this.getWidth(item),
          lottie_file: item.lottie_file,
          video: item.bg_video ? item.bg_video : item.bg_video_file,
          media: item.media
        };
      });
    },
    length() {
      return this.items.length;
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.currentSlide = swiper.activeIndex;
      // console.log('carousel', swiper.activeIndex)
      // console.log('swiper init', swiper.activeIndex, swiper.realIndex)
      // console.log(this.currentSlide)
    },
    getWidth(item) {
      const { width, height } = item.media;
      let ratio = width / height;
      if (item.bg_video) {
        // console.log('video file', ratio, item.video_portrait)
        ratio = item.video_portrait ? 0.78 : 1.5;
      }
      const portraitWidth = window.innerWidth > 1280 ? "40vw" : "60vw";
      if (window.innerWidth < 640) return { width: "100vw" };
      else return { width: ratio < 1 ? portraitWidth : "80vw" };
    },
    nextSlide() {
      // console.log('nextSlide', this.changeHere)
      if (this.changeHere) return;

      this.changeHere = true;
      this.instantMode = false;
      this.swiper.slideNext();
      this.pause();
    },
    prevSlide() {
      // console.log('prevSlide', this.changeHere)
      if (this.changeHere) return;

      this.changeHere = true;
      this.instantMode = false;
      this.swiper.slidePrev();
      this.pause();
    },
    play() {
      this.pause(); // reset timer
      if (this.visible) {
        this.timer = setTimeout(() => {
          this.nextSlide();
        }, 3000);
      }
    },
    pause() {
      clearTimeout(this.timer);
    },
    onScrollStart() {
      // console.log('onScrollStart')
      if (this.changeHere) return;

      this.instantMode = true;
      // this.changeHere = false
    },
    onScrollChange(val) {
      if (this.changeHere) return;

      this.currentSlide = val;

      this.swiper.slideToLoop(val, 0);
      this.pause();
    },
    slideChange() {
      // console.log('slide change...')
      // console.log(this.swiper?.realIndex)
      // console.log(this.currentSlide)
      // console.log(this.swiper?.realIndex % this.items.length)

      if (!this.swiper || !this.changeHere) return;

      this.currentSlide = this.swiper.realIndex; // % this.items.length
    },
    transitionEnd() {
      // console.log('transitionEnd')
      // this.changeHere = false
    },
    touchStart() {
      // console.log('touchStart')
      // this.instantMode = true
      this.changeHere = true;
    },
    touchEnd() {
      // console.log('touchEnd')
      // this.instantMode = false
    },
    onMoved() {
      // console.log('thumbs moved...')
      this.changeHere = false;
      this.instantMode = false;
    }
  },
  mounted() {
    // console.log('main carousel loaded')
    window.addEventListener("blur", this.pause);
  },
  destroyed() {
    window.removeEventListener("blur", this.pause);
  },
  components: {
    RespImg,
    CarouselThumbnails,
    CarouselThumbs,
    LottieAnimation,
    RespBgVideo,
    Swiper,
    SwiperSlide
  }
};
</script>
