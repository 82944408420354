<template lang="pug">
.relative.relative.py-20px.overflow-hidden.w-min(:style="{background: slice.primary.bg_color}", :class="sliderheight" v-intersects, @intersects="visible = true", @outersects="visible = false")
  figure.slice-carousel.w-auto.h-full.mb-100px
    swiper.w-full.h-full.swiper(ref="container", :loop="true", :looped-slides="items.length" :speed="500", slides-per-view="auto", :centered-slides="true", :space-between="20", :grab-cursor="true", @swiper="onSwiper", :update-on-images-ready="true", @imagesReady="imgs" :initial-slide="0")
      //- .swiper-wrapper
      swiper-slide.swiper-slide.w-auto.relative(v-for='(item, index) in items', :key="index", :class="item.class", :style="item.style")
        lottie-animation.h-full(v-if="item.lottie_file", :data="item.lottie_file")
        resp-bg-video(v-else-if="item.video", :video="item.video", :always="true")
        img.h-full(v-else-if="item.image", :src="item.image.url")
        prismic-link.absolute.inset-0(v-if="item.link && item.link.link_type !== 'Any'", :field="item.link")

  nav.absolute.overlay.md-flex.justify-between.z-10.hidden.pointer-events-none
    button.w-1x5.cursor-pointer.pointer-events-auto(@click="onPrevClick", aria-label="Previous Image", style="cursor:w-resize")
    button.w-1x5.cursor-pointer.pointer-events-auto(@click="onNextClick", aria-label="Next Image", style="cursor:e-resize")
</template>

<script>
import RespImg from "@/components/RespImg";
import RespBgVideo from "@/components/RespBgVideo.vue";
import LottieAnimation from "../../components/LottieAnimation.vue";
import { Swiper, SwiperSlide } from 'swiper-vue2';
import { mapGetters } from 'vuex';

export default {
  name: "SliceMarginsCarousel",
  props: ["slice"],
  data() {
    return {
      timer: null,
      visible: false,
      swiper: null
    };
  },
  computed: {
    sliderheight () {
      return this.isPortrait ? 'h-mob-slide' : 'h-75vh'
    },
    items() {
      let justLottie = 0

      const items = this.slice.items.map(item => {
        const isAnim = !!item.lottie_file

        if (isAnim) justLottie++

        const classes = []
        if (!(item.bg_image && item.bg_image.dimensions) && !isAnim) {
          classes.push('md-w-4x12vw', 'w-full')
        }
        if (this.slice.primary.corners) {
          classes.push('rounded-lg', 'overflow-hidden')
        }

        return {
          class: classes,
          image: item.bg_image,
          video: item.bg_video ? item.bg_video : item.bg_video_file,
          lottie_file: item.lottie_file,
          width: item.width,
          height: item.height,
          link: item.link,
          style: {
            aspectRatio: isAnim ? (item.width / item.height) : undefined
          }
        };
      });

      if (justLottie === items.length) {
        setTimeout(() => {
          if (!this.swiper) return

          // else swiper-wrapper is way off
          this.swiper.update()
        }, 1500)
      }

      return items;
    },
    ...mapGetters(['isPortrait'])
  },
  watch: {
    visible (vis) {
      if (vis) this.play()
      else this.pause()
    }
  },
  methods: {
    onSwiper (swiper) {
      this.swiper = swiper
    },
    imgs () {
      if (!this.swiper) return
      const qty = this.items.length
      this.swiper.slideTo(qty, 0, false)
    },
    onPrevClick() {
      this.prevSlide();
      this.pause();
    },
    onNextClick() {
      this.nextSlide();
      this.pause();
    },
    prevSlide() {
      this.swiper?.slidePrev();
    },
    nextSlide() {
      this.swiper?.slideNext();
    },
    play() {
      this.pause(); // reset timer
      if (this.visible) {
        this.timer = setTimeout(() => {
          this.nextSlide();
        }, 3000);
      }
    },
    pause() {
      clearTimeout(this.timer);
    }
  },
  mounted() {
    window.addEventListener("blur", this.pause);
  },
  destroyed() {
    window.removeEventListener("blur", this.pause);
    this.swiper.destroy();
  },
  components: {
    RespImg,
    RespBgVideo,
    Swiper,
    SwiperSlide,
    LottieAnimation
  }
};
</script>

<style>
.carouselslidefade-enter-active {
  transition: opacity 600ms;
  z-index: 2;
}
.carouselslidefade-leave-active {
  transition: opacity 0s 600ms;
  z-index: 1;
}
.carouselslidefade-enter,
.carouselslidefade-leave-to {
  opacity: 0;
}
.carouselslidefade-enter-to,
.carouselslidefade-leave {
  opacity: 1;
}
</style>
