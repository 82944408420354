// Adapted from https://prismic.io/docs/technologies/vue-setup
// because doesn't seem to work out of the box...
// see:
// * https://github.com/prismicio/prismic-vue/issues/27
// * https://github.com/nuxt-community/prismic-module/issues/60

import prismicDOM from 'prismic-dom'
import linkResolver from './link-resolver' // Update the path to your Link Resolver

const Elements = prismicDOM.RichText.Elements

export default function(type, element, content, children) {
  // const url = prismicDOM.Link.url(element.linkTo, linkResolver)
  const url = prismicDOM.Link.url(element.data, linkResolver)

  // Generate links to Prismic Documents as <router-link> components
  if (type === Elements.hyperlink) {
    if (element.data.link_type === 'Document') {
      // return `<router-link to="${url}">${children.join('')}</router-link>`
      return `<a class="prismic-router-link" href="${url}">${children.join('')}</a>`
    }
  }

  // If the image is also a link to a Prismic Document, it will return a <router-link> component
  if (type === Elements.image) {
    if (element.data) {
      if (element.data.link_type === 'Document') {
        return `<img src="${element.url}" alt="${element.alt ||
          ''}" copyright="${element.copyright || ''}">`
      }
    }
  }

  // Return null to stick with the default behavior for everything else
  return null
}