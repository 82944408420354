<template lang="pug">
  div
    slot
</template>

<script>
export default {
  name: 'IntersectionObserver',
  props: ['threshold', 'log'],
  data () {
    return {
      observer: null
    }
  },
  methods: {
    observe () {
      if (!window.IntersectionObserver) return this.$emit('visible')
      // reset
      this.unobserve()
      // observe
      const callback = entries => {
        const ratio = entries[0] && entries[0].intersectionRatio
        if (typeof this.threshold === 'number') {
          return ratio && ratio >= this.threshold ? this.$emit('visible') : this.$emit('hidden')
        } else {
          this.$emit('ratio', ratio)
          this.$emit('entry', entries[0])
        }
      }
      this.observer = new IntersectionObserver(callback, { threshold: this.threshold })
      this.observer.observe(this.$el)
    },
    unobserve () {
      if (this.observer) this.observer.unobserve(this.$el)
    }
  },
  created () {
    this.threshold = this.threshold || 0.5
  },
  mounted () {
    this.observe()
    this.$emit('mounted')
  },
  beforeDestroy () {
    this.unobserve()
  }
}
</script>

<style>
</style>
