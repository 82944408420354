<template lang="pug">
observer.absolute.overlay-px.overflow-hidden.bg-black(v-if="renderedSrc", :threshold="0.05", @visible="play", @hidden="pause")
  video.absolute.overlay.object-cover.object-center.transition-opacity.duration-200(ref="video", :src="renderedSrc", loop, muted, autoplay, playsinline, @playing="onPlaying", :class="{'opacity-0': !loaded}", @error="onError")
</template>

<script>
import { mapGetters } from "vuex";
import Observer from "./IntersectionObserver";
export default {
  name: "RespBgVideo",
  props: ["id", "video", "byHeight", "always"],
  data() {
    return {
      length: 0,
      loaded: false,
      src: null
    };
  },
  computed: {
    ...mapGetters(["isPortrait"]),
    renderedSrc() {
      return (this.video && this.video.url) || this.src;
    }
  },
  methods: {
    setSrc() {
      if (this.id) {
        const heights = [480, 720, 1080];
        const widths = [720, 1280, 1600];
        const grp = this.byHeight ? heights : widths;
        const dpx = 1; // window.devicePixelRatio || 1
        const bffr = 1.49; // use 720p up to 1920 (1920/1280) // formerly 1.5 when using dpx
        const size =
          grp.find(sz => sz * bffr >= this.length * dpx) || grp[grp.length - 1];
        let params = this.byHeight ? `h_${size}` : `w_${size}`;
        // crop to portrait on portrait devices
        if (this.byHeight && this.isPortrait) {
          const w = (size * 768) / 1024; // ipad has widest portrait ?
          params += `,w_${w}`;
        }
        this.src =
          process.env.VUE_APP_CLOUDINARY_URL +
          `/video/upload/${params}/${this.id}.mp4`;
      }
    },
    measure() {
      this.length = this.byHeight
        ? this.$el.offsetHeight
        : this.$el.offsetWidth;
    },
    play() {
      const canplay = process.env.VUE_APP_VIDEOS_AUTOPLAY !== "false";
      return (
        canplay &&
        this.$refs.video &&
        this.$refs.video.paused &&
        this.$refs.video.play()
      );
    },
    pause() {
      if (this.always) return;
      return (
        this.$refs.video && !this.$refs.video.paused && this.$refs.video.pause()
      );
    },
    onPlaying() {
      this.$emit("playing");
      this.loaded = true;
    },
    onError() {
      // video 404 (?)
      this.$emit("error");
    }
  },
  mounted() {
    this.measure();
    this.setSrc();
    if (this.always) {
      this.play();
    }
  },
  watch: {
    $route(to) {
      if (to.hash.startsWith("#info")) return this.pause();
      return setTimeout(() => this.play(), 300);
    }
  },
  components: { Observer }
};
</script>
