<template>
  <observer ref="obsv" :style="styles" @visible="render = true" :threshold="0.1" class="slice--glyph-table relative w-full p-15 md-p-20">
    <div>
      <h2 v-if="title" class="text-35 font-medium mb-85">{{ title }}</h2>
      <ul v-for="table in tables" :style="tableStyle" class="py-px bg-inherit">
        <li v-for="item in table.items" class="block w-1x6 md-w-1x12 lg-w-1x18 xl-w-1x27 float-left border border-inherit bg-inherit -ml-px -mb-px">
          <img v-if="render" :src="item.glyph.url" :alt="item.glyph.alt || 'Typeface Glyph'" class="block w-full"/>
        </li>
      </ul>
      <div v-if="fontdueSlug" class="mt-30">
        <button @click="showFontdue = !showFontdue" class="btn btn--primary text-24 cursor-pointer">{{ btn }} &nearr;</button>
      </div>

      <transition name="fade">
        <div v-if="showFontdue" :style="styles" class="fixed inset-0 p-15 md-p-20 z-50 overflow-auto">
          <h2 class="text-35 font-medium mt-10">{{ title }}</h2>
          <fontdue-character-viewer :collection-slug="fontdueSlug"/>
        </div>
      </transition>
    </div>
  </observer>
</template>

<script>
import { mapState } from "vuex";
import _get from "lodash/get";
import Observer from "@/components/IntersectionObserver";
export default {
  name: "Slice--GlyphTable",
  props: ["slice"],
  data() {
    return {
      tables: [],
      render: false,
      afterScroll: null,
      showFontdue: false
    };
  },
  computed: {
    ...mapState(["bus"]),
    title () {
      return this.slice.primary.title1
    },
    styles () {
      return {
        backgroundColor: this.slice.primary.bg_color || 'black',
        color: this.slice.primary.color || 'white',
      }
    },
    tableStyle() {
      return {
        // width: `calc(100% + ${cols}px)`,
        borderColor: "rgba(255,255,255,0.1)" // this.slice.primary.table_border_color
      }
    },
    fontdueSlug () {
      return this.slice?.primary.collection_slug
    },
    btn () {
      return this.slice.primary.btn_text
    }
  },
  methods: {
    getTable(id) {
      return this.$prismic.client.getByID(id).then(doc => {
        this.tables = _get(doc, "data.body") || [];
      });
    },
    renderOnFirstScroll() {
      this.render = true;
    }
  },
  watch: {
    render(rnd) {
      if (rnd) this.$refs.obsv.unobserve();
    }
  },
  created() {
    this.getTable(this.slice.primary.glyph_table_link.id);
  },
  components: { Observer }
};
</script>

<style lang="postcss">
.slice--glyph-table ul:after {
  content: "";
  display: block;
  clear: both;
}

/* HOVER/TOUCH:ACTIVE EFFECT */
.slice--glyph-table li {
  /*transition: transform .1s ease;*/
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0);
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    transition: width 80ms 0s, transform 80ms 0s, border-color 0s,
      background-color 0ms 80ms;
    /*pointer-events: none;*/
  }
}
.slice--glyph-table li:hover {
  /*position: relative;*/
  z-index: 10;
  /*transition: transform .1s 40ms ease;
  transform: scale(3) translate(-0.1px, -0.1px);
  transform-origin: center center;*/
  & img {
    pointer-events: none;
    width: calc(300% + 6px);
    background-color: rgb(0, 0, 0, 1);
    max-width: none;
    border-color: inherit;
    transition: width 80ms 40ms, transform 80ms 40ms, border-color 80ms 40ms,
      background-color 0ms 0ms;
  }
}

/*
1. TABLE WIDTHS
   (account for negative margin on cells)
2. CELL HOVER TRANSF-ORIGIN
   (left/right edge anim. from edge)
*/
.slice--glyph-table {
  & ul {
    width: calc(100% + 6px);
  }
  & li {
    &:nth-child(6n + 1) {
      /*transform-origin: center left;*/
      & img {
        left: 0;
        transform: translate(0, -50%);
      }
    }
    &:nth-child(6n) {
      /*transform-origin: center right;*/
      & img {
        left: auto;
        right: 0;
        transform: translate(0, -50%);
      }
    }
  }
}

/* --bkpt-md */
@media (min-width: 600px) {
  .slice--glyph-table {
    & ul {
      width: calc(100% + 12px);
    }
    & li {
      /* reset */
      &:nth-child(6n + 1),
      &:nth-child(6n) {
        /*transform-origin: center center;*/
        & img {
          left: 50%;
          right: auto;
          transform: translate(-50%, -50%);
        }
      }
      /* 9 cols */
      &:nth-child(12n + 1) {
        /*transform-origin: center left;*/
        & img {
          left: 0;
          transform: translate(0, -50%);
        }
      }
      &:nth-child(12n) {
        /*transform-origin: center right;*/
        & img {
          left: auto;
          right: 0;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

/* --bkpt-lg */
@media (min-width: 960px) {
  .slice--glyph-table {
    & ul {
      width: calc(100% + 18px);
    }
    & li {
      /* reset */
      &:nth-child(6n + 1),
      &:nth-child(6n),
      &:nth-child(12n + 1),
      &:nth-child(12n) {
        /*transform-origin: center center;*/
        & img {
          left: 50%;
          right: auto;
          transform: translate(-50%, -50%);
        }
      }
      /* 14 cols */
      &:nth-child(18n + 1) {
        /*transform-origin: center left;*/
        & img {
          left: 0;
          transform: translate(0, -50%);
        }
      }
      &:nth-child(18n) {
        /*transform-origin: center right;*/
        & img {
          left: auto;
          right: 0;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

/* --bkpt-xl */
@media (min-width: 1441px) {
  .slice--glyph-table {
    & ul {
      width: calc(100% + 27px);
    }
    & li {
      /* reset */
      &:nth-child(6n + 1),
      &:nth-child(6n),
      &:nth-child(12n + 1),
      &:nth-child(12n),
      &:nth-child(18n + 1),
      &:nth-child(18n) {
        /*transform-origin: center center;*/
        & img {
          left: 50%;
          right: auto;
          transform: translate(-50%, -50%);
        }
      }
      /* 27 cols */
      &:nth-child(27n + 1) {
        /*transform-origin: center left;*/
        & img {
          left: 0;
          transform: translate(0, -50%);
        }
      }
      &:nth-child(27n) {
        /*transform-origin: center right;*/
        & img {
          left: auto;
          right: 0;
          transform: translate(0, -50%);
        }
      }
    }
  }
}
</style>
