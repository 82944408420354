<template lang="pug">
  noscript
    template(v-for="(prj, i) in projects", v-if="i > 0 && prj && prj.data")
      article
        //- title
        h2(v-if="prj.data.lede") {{ $prismic.richTextAsPlain(prj.data.lede) }}
        h2(v-else) {{ $prismic.richTextAsPlain(prj.data.title) }}
        //- text
        prismic-rich-text(v-for="(slice, i) in txts(prj)", :field="slice.primary.text", :key="i")
</template>

<script>
import _get from 'lodash/get'
export default {
  name: 'SEOFeedText',
  props: ['projects'],
  inheritAttrs: false,
  methods: {
    txts (prj) {
      const slices = _get(prj, 'data.body_landscape') || []
      return slices.filter(slice => slice.slice_type === 'text')
    }
  }
}
</script>

<style>
</style>
