<script>
export default {
  name: "BackdropFilter",
  props: {
    titleHover: Boolean,
    theme: Object,
  },
  data() {
    return {
      width: 0,
      translateX: 0,
      speed: 0,
      lastState: null,
      currentState: null,
      tablet: {
        width: 0,
        translateX: 0,
        opacity: 0,
      },
    };
  },
  computed: {
    projectMenuActive() {
      return this.$store.state.projectMenuActive;
    },
    titleActive() {
      return this.$store.state.titleActive;
    },
    menuActive() {
      return this.$store.state.menuActive;
    },
  },
  watch: {
    titleHover() {
      this.currentState = null;
      this.lastState = null;
      this.updateWidth();
    },
    menuActive() {
      this.currentState = null;
      this.lastState = null;
      this.updateWidth();
    },
    projectMenuActive() {
      this.currentState = null;
      this.lastState = null;
      this.updateWidth();
    },
    "$store.state.title"() {
      this.currentState = null;
      this.lastState = null;
      this.updateWidth_Title();
    },
    "$route.path"() {
      this.currentState = null;
      this.lastState = null;
      this.updateWidth_Title();
    },
  },
  mounted() {
    this.tick();
    window.addEventListener("resize", () => {
      this.currentState = null;
      this.lastState = null;
      this.updateWidth();
    });
  },
  methods: {
    updateWidth_Title() {
      const projectTitleEl = document.querySelector("#appProjectTitle");
      if (projectTitleEl) {
        this.translateX = projectTitleEl.offsetLeft;
        this.width = projectTitleEl.offsetWidth;
        this.tablet.translateX = projectTitleEl.offsetLeft;
      }
      this.tablet.width = 0;
      this.tablet.opacity = 0;
    },
    updateWidth_ProjectMenu() {
      const projectMenuEl = document.querySelector("#appProjectMenu");
      if (projectMenuEl) {
        this.translateX = projectMenuEl.offsetLeft;
        this.width = projectMenuEl.offsetWidth;
        this.tablet.translateX = projectMenuEl.offsetLeft;
      }
      this.tablet.width = 0;
      this.tablet.opacity = 0;
    },
    updateWidth_HomeMenu() {
      const appHomeMenu = document.querySelector("#appHomeMenu");
      if (!appHomeMenu) return;
      if (appHomeMenu) {
        this.translateX = appHomeMenu.offsetLeft;
        this.width = appHomeMenu.offsetWidth;
        this.tablet.translateX = appHomeMenu.offsetLeft;
      }

      const links = appHomeMenu.querySelectorAll("a");

      // Active link.
      const exactActive = appHomeMenu.querySelector(
        ".router-link-exact-active"
      );
      let activeLink = appHomeMenu.querySelector(".router-link-active");
      if (exactActive) activeLink = exactActive;
      if (!activeLink) activeLink = appHomeMenu.querySelectorAll("a")[0];

      const currentLinkIndex = Array.from(links).indexOf(activeLink);
      let pixelChange = 0;

      if (currentLinkIndex === 0) pixelChange = 1;
      if (currentLinkIndex === links.length - 1) pixelChange = 1;

      const width = activeLink.offsetWidth;
      const left = activeLink.offsetLeft;
      this.tablet = {
        width: width - 2,
        translateX: left + pixelChange,
        opacity: 1,
      };
    },
    async updateWidth() {
      const lastWidth = this.width;
      if (this.titleActive) {
        this.currentState = "titleActive";
        if (this.lastState !== this.currentState || this.width === 0)
          this.updateWidth_Title();
      }
      if (!this.titleActive && this.projectMenuActive) {
        this.currentState = "projectMenuActive";
        if (this.lastState !== this.currentState || this.width === 0)
          this.updateWidth_ProjectMenu();
      }
      if (this.titleHover) {
        this.currentState = "homeMenuActive";
        if (this.lastState !== this.currentState || this.width === 0)
          this.updateWidth_HomeMenu();
      }
      if (!this.titleActive && !this.projectMenuActive) {
        this.currentState = "homeMenuActive2";
        if (this.lastState !== this.currentState || this.width === 0)
          this.updateWidth_HomeMenu();
      }

      this.lastState = this.currentState;
      if (lastWidth < this.width) {
        this.speed = 150;
      } else {
        this.speed = 600;
      }
    },

    tick() {
      this.updateWidth();
      window.requestAnimationFrame(() => this.tick());
    },
  },
};
</script>

<template>
  <div
    v-if="theme"
    class="relative z-10 flex items-center justify-center overflow-hidden leading-tight pointer-events-none header-row-height"
  >
    <div
      :style="{
        width: width ? width + 'px' : '100px',
        backdropFilter: theme.show_glass ? 'blur(96px)' : 'none',
        // backdropFilter: theme.show_glass ? 'invert(100%)' : 'none',
        background: !theme.show_glass
          ? theme.bar_bgcolor
          : 'rgba(13,13,13,.25)',
        transition: `all ${speed}ms cubic-bezier(.16,1,.3,1)`,
        willChange: 'transform, width',
      }"
      class="block w-full h-full overflow-hidden rounded-lg"
    ></div>
    <div
      class="absolute left-0 flex items-center rounded-lg justify-items-center"
      :style="{
        width: tablet.width + 'px',
        opacity: tablet.width > 0 ? tablet.opacity : 0,
        height: 'calc(100% - 2px)',
        backdropFilter: theme.show_glass ? 'blur(96px)' : 'none',
        background: 'rgba(255, 255, 255, 0.15)',
        transform: `translateX(${tablet.translateX}px)`,
        willChange: 'transform, width',
        transition: `opacity .6s cubic-bezier(.16,1,.3,1), transform ${speed}ms cubic-bezier(.16,1,.3,1)`,
      }"
    ></div>
  </div>
</template>
