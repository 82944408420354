<!--
  Prismic Slices Section
  * rather than import all slice components here,
    import them in the parent component that uses <slices-body>
    and pass them in as `templates` prop
  * `slices` prop is the actual prismic slices content body
-->

<template lang="pug">
section.slices-body
  //- slices...
  component(
  v-for="(slice, i) in slices",
  v-if="render(slice, i)",
  :is="templates['slice-' + slice.slice_type]",
  :slice="slice",
  :prjNo="index",
  :sliceNo="i",
  :isLanding="index === 0 && i === firstFullSliceIndex",
  :preload="preload",
  v-on="$listeners",
  :data-slice-type="slice.slice_type",
  :key="i")
</template>

<script>
export default {
  name: "SlicesBody",
  props: ["slices", "templates", "index", "intro", "loopGap", "preload"],
  computed: {
    firstFullSliceIndex() {
      // return index of first "full" slice
      // TODO: better logic here...
      return this.slices.findIndex(
        slice => slice.slice_type === "full" || slice.slice_type === "full_a"
      );
    }
  },
  methods: {
    render(slice, i) {
      // intro = animation sequence before can scroll into site
      const isBelowIntroSlice = this.intro && i > this.firstFullSliceIndex;
      // `loop` is when a project is duplicated at the bottom of the DOM to similate the scroll loop
      const isBelowLoopSlice = this.loopGap && i > this.firstFullSliceIndex;
      const hidden = isBelowLoopSlice || isBelowIntroSlice;
      // template exists?
      const hasTemplate = this.templates["slice-" + slice.slice_type];
      return !hidden && hasTemplate;
    }
  }
};
</script>
