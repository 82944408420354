<template lang="pug">
.project-body.relative(:data-title="lede", :data-text-color="text_color", :data-bg-color="bg_color", :style="{color: text_color, backgroundColor: bg_color}")
  //- body
  slices-body(:slices="slices", :templates="templates", :index="index", :loopGap="loopGap", :preload="preload")

  //- triggers
  template(v-if="!loopGap && !preload")
    //- title observer
    .absolute.h-full.w-full.top-0.left-0.z-0.pointer-events-none(style="height:calc(100% - 10px)")

    //- fade to black layer
    observer.absolute.z-1.h-screen.w-full.bottom-0.left-0.bg-black.pointer-events-none(v-if="fadeEnd", :style="{height: '90vh', opacity: scrimOpacity}", :threshold="steps", @entry="onScrimEntry")

    //- observer to lazyload next
    observer.absolute.bottom-0.left-0.w-10.z-20.pointer-events-none(v-if="watchEnd && !intro", :threshold="0.01", @visible="onEnding", style="height:50%;max-height:500vh;")
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SlicesBody from "@/components/SlicesBody";
import Observer from "@/components/IntersectionObserver";
// slices
import SliceFull from "@/slices/SliceFull";
import SliceFullStickyFore from "@/slices/SliceFullStickyFore";
import SliceSplit from "@/slices/SliceSplit";
import SliceText from "@/slices/SliceText";
import SliceSubscribeForm from "@/slices/SliceSubscribeForm";
import SliceTickerText from "@/slices/SliceTickerText";
import SliceGlyphTable from "@/slices/SliceGlyphTable";
import SliceMargins from "@/slices/SliceMargins";
import SliceCarousel from "@/slices/SliceCarousel";
import SliceMarginsCarousel from "@/slices/SliceMarginsCarousel";
import SliceGap from "@/slices/SliceGap";
import SliceContact from "@/slices/SliceContact";
import SliceTwoColumnText from "@/slices/SliceTwoColumnText";
import SliceHoverLinks from "@/slices/SliceHoverLinks";
import SliceFontFamily from "@/slices/SliceFontFamily";
import SliceTypeTester from "@/slices/SliceTypeTester";
import SliceFontdueGlyphs from "@/slices/SliceFontdueGlyphs";
import SliceDetails from "@/slices/DetailsSlice";

export default {
  name: "ProjectBody",
  props: ["doc", "index", "loopGap", "preload", "fadeEnd"],
  data() {
    return {
      scrimOpacity: 0,
      steps: Array(100)
        .fill(0)
        .map((m, i) => i / 100),
      watchEnd: true,
      templates: {
        "slice-full_a": SliceFull,
        "slice-full": SliceFull,
        "slice-full_sticky_fore": SliceFullStickyFore,
        "slice-full_b": SliceFullStickyFore,
        "slice-split": SliceSplit,
        "slice-text": SliceText,
        "slice-glyph_table": SliceGlyphTable,
        "slice-subscribe_form": SliceSubscribeForm,
        "slice-ticker_text": SliceTickerText,
        "slice-margins": SliceMargins,
        "slice-carousel": SliceCarousel,
        "slice-carousel_margins": SliceMarginsCarousel,
        "slice-gap": SliceGap,
        "slice-contact": SliceContact,
        "slice-two-column-text": SliceTwoColumnText,
        "slice-hover-links": SliceHoverLinks,
        "slice-font_family": SliceFontFamily,
        "slice-type_tester": SliceTypeTester,
        "slice-fontdue_glyphs": SliceFontdueGlyphs,
        "slice-accordion": SliceDetails,
        "slice-two_column_text": SliceTwoColumnText,
        // 'slice-top_bar_theme': SliceTopBarTheme
      },
    };
  },
  computed: {
    ...mapState(["title", "intro", "loading", "main"]),
    ...mapGetters(["aspectRatio", "isPortrait"]),
    slices() {
      // TEMPORARY
      // const key = this.isPortrait ? 'body_portrait' : 'body_landscape'
      const isLandscapeOnly = this.main?.landscape_content_only;
      const key =
        this.isPortrait && !isLandscapeOnly
          ? "body_portrait"
          : "body_landscape";
      return (
        this.doc &&
        this.doc.data[key].filter(
          (slice, i) => !this.preload || i < this.preload
        )
      );
    },
    lede() {
      // text that appears in "top bar"
      const text = (field) =>
        this.doc && this.$prismic.richTextAsPlain(this.doc.data[field]);
      return text("lede_portrait") &&
        this.isPortrait &&
        text("lede_portrait").length
        ? text("lede_portrait")
        : text("lede");
    },
    bg_color() {
      if (!this.doc) return undefined;
      if (!this.doc.data) return undefined;
      if (this.doc.data.bg_color) return this.doc && this.doc.data.bg_color;
      else return undefined;
    },
    text_color() {
      if (!this.doc) return undefined;
      if (!this.doc.data) return undefined;
      if (this.doc.data.text_color) return this.doc && this.doc.data.text_color;
      else return undefined;
    },
  },
  methods: {
    style(key) {
      return {
        color: this.doc.data[key + "_text_color"],
        background: this.doc.data[key + "_bg_color"],
      };
    },
    setTitle(newTitle) {
      if (newTitle === this.title) return;

      this.$store.commit("SET_TITLE", newTitle);
    },

    onScrimEntry(entry) {
      const rect = entry.boundingClientRect;
      // invisible when top edge hasn't crossed top of window
      if (rect.top > 0) {
        if (this.scrimOpacity > 0) {
          this.scrimOpacity = 0;
        }
        return;
      }
      // fade to black:
      let opacity = Math.min(1, Math.abs(rect.top) / rect.height);
      requestAnimationFrame(() => {
        this.scrimOpacity = opacity;
      });
    },
    onEnding() {
      this.$emit("ending");
      this.watchEnd = false;
    },
  },
  created() {
    // this.setTitle(this.lede);
  },
  mounted() {
    this.$emit("mounted");
  },
  components: { SlicesBody, Observer },
};
</script>
