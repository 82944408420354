export default function (doc) {
  switch (doc.type) {
    case 'info': return '#info'
    case 'note': return `#info_${doc.uid}`
    case 'project_feed': return `/${doc.uid}`
    case 'project': return `/projects/${doc.uid}`
    case 'main': return `/`
    default: return '/'
  }
}
