import Vue from "vue";
import Vuex from "vuex";

// SETUP INTRO SETTINGS =====
const envSettings = process.env.VUE_APP_INTRO_CARD_SETTINGS;

// default settings (bst style)
let introSettings = { type: "bst", bgColor: "white", textColor: "black" };

// load .env / Netlify settings
try {
  introSettings = JSON.parse(envSettings);
} catch (e) {
  // empty/invalid json
  console.warn(
    `Couldn't load intro card settings from environment variables. Using default: ${JSON.stringify(
      introSettings
    )}\n\nValue: ${envSettings}\nError: ${e}`
  );
}
// END INTRO SETTINGS ======

Vue.use(Vuex);

const vue = new Vue();
const eventBus = new Vue();
const canHover = window.matchMedia("(hover:hover)").matches;

export default new Vuex.Store({
  state: {
    bus: eventBus,
    winW: window.innerWidth,
    winH: window.innerHeight,
    canHover: canHover,

    loading: true,
    blur: true,
    title: "",
    titleTheme: [],
    titleActive: true,
    projectMenuActive: false,
    intro: true,
    leavingIntro: false,
    introSettings,
    preload: true, // preload other pages

    scrolledOut: false,

    main: null,
    feeds: [],
    projects: [],
    notes: [],

    fontdueLoaded: false,
    fontdueId: null,
  },
  getters: {
    isBST(state) {
      return state.introSettings && state.introSettings.type === "bst";
    },
    aspectRatio(state) {
      return state.winW / state.winH;
    },
    isPortrait(state) {
      return state.winW / state.winH < 1.2;
    },
    header(state) {
      // first slice of header_types slice body
      return state.main?.header_types[0] || {};
    },
    allFeedChildren(state) {
      return state.feeds?.map((feed) => feed.data.body);
    },
  },
  mutations: {
    SET_TITLE(state, title) {
      // console.log("SET_TITLE", title);
      const siteTitle = state.main?.title[0].text || "...";
      state.title = title || siteTitle;
    },
    SET_TITLE_THEME(state, theme) {
      // default theme
      const header = state.main?.header_types[0] || { primary: {} };
      const base = [header.primary.bar_bgcolor, header.primary.bar_text_color];
      // apply
      state.titleTheme = theme || base || [];
    },
    TITLE_ACTIVE(state) {
      state.titleActive = true;
    },
    TITLE_INACTIVE(state) {
      state.titleActive = false;
    },
    PROJECT_MENU_ACTIVE(state) {
      state.projectMenuActive = true;
    },
    PROJECT_MENU_INACTIVE(state) {
      state.projectMenuActive = false;
    },
    SET_MAIN(state, doc) {
      state.main = doc;
    },
    SET_FEEDS(state, docs) {
      state.feeds = docs;
    },
    SET_PROJECTS(state, docs) {
      // TEMPORARY - EXCLUDE INDEX ORDER: 999 TO PREP JUNE 2020 REVISIONS !!
      // docs = docs.filter(doc => doc.data.order < 999)
      state.projects = docs;
    },
    SET_NOTES(state, docs) {
      state.notes = docs;
    },
    SET_WIN_H(state, h) {
      state.winH = h;
    },
    SET_WIN_W(state, w) {
      state.winW = w;
    },
    LEAVE_INTRO(state) {
      state.leavingIntro = true;
    },
    END_INTRO(state) {
      state.intro = false;
    },
    END_LOADING(state) {
      state.loading = false;
    },
    BLUR_ON(state) {
      state.blur = true;
    },
    BLUR_OFF(state) {
      state.blur = false;
    },
    PRELOAD_END(state) {
      state.preload = false;
    },
    FONTDUE_LOAD(state) {
      state.fontdueLoaded = true;
    },
    FONTDUE_ID(state, id) {
      state.fontdueId = id;
    },
    FONTDUE_ID_RM(state) {
      state.fontdueId = null;
    },
  },
  actions: {
    async getMain({ commit }) {
      return vue.$prismic.client.getSingle("main").then((doc) => {
        commit("SET_MAIN", doc.data);
        commit("SET_TITLE_THEME");
      });
    },
    async getFeeds({ commit }) {
      return vue.$prismic.client
        .query(vue.$prismic.Predicates.at("document.type", "project_feed"))
        .then(({ results }) => commit("SET_FEEDS", results));
    },
    async getProjects({ commit }) {
      return vue.$prismic.client
        .query(vue.$prismic.Predicates.at("document.type", "project"), {
          pageSize: 100,
        })
        .then(({ results }) => commit("SET_PROJECTS", results));
    },
    async getNotes({ state, commit }) {
      if (state.notes.length) return;
      return vue.$prismic.client
        .query(vue.$prismic.Predicates.at("document.type", "note"), {
          pageSize: 100,
        })
        .then(({ results }) => commit("SET_NOTES", results));
    },
  },
});
