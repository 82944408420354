<template lang="pug">
  .landing-space(:style="{height: ht + 'px'}", v-show="visible")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'LandingSpace',
  data () {
    return {
      visible: null,
      afterScroll: null
    }
  },
  computed: {
    ...mapState(['winH', 'intro', 'bus']),
    ...mapGetters(['isPortrait']),
    ht () {
      return this.winH
    }
  },
  methods: {
    onScroll () {
      clearTimeout(this.afterScroll)

      // hide after scroll
      this.afterScroll = setTimeout(() => {
        const y = window.pageYOffset
        // console.log(y, this.ht)

        if (y >= this.ht) {
          // hide space
          this.visible = false

          this.$nextTick(() => {
            this.bus.$emit('landingCollapsed')
            window.removeEventListener('scroll', this.onScroll)
            // update scroll if needed (Safari)
            if (y !== window.pageYOffset) return
            window.scroll(0, window.pageYOffset - this.ht)
          })
        }
      }, 100)
    }
  },
  mounted () {
    if (!this.isPortrait && this.intro) {
      this.visible = true
      window.addEventListener('scroll', this.onScroll)
    }
  }
}
</script>

<style>
</style>
