<template lang="pug">
//- set position and size in parent
//- ! disabled overflow-hidden because of pixel gap on resp-bg-video (bottom edge)
.slice-bg.top-0.left-0.overflow-hidden-off
  //- (video
  template(v-if="body.bg_video || body.bg_video_file.url")
    resp-bg-video(:id="body.bg_video", :video="body.bg_video_file", v-bind="$attrs", @playing="$emit('load')", @error="$emit('error')", :class="rounded ? 'rounded-lg overflow-hidden': ''")
  //- (image)
  template(v-else-if="body.bg_img.url")
    resp-img.block.absolute.overlay-px.object-cover.object-center(:src="body.bg_img.url", :alt="body.bg_img.alt", :byHeight="imgIsWiderAR(body.bg_img)", @load="$emit('load')", :class="rounded ? 'rounded-lg overflow-hidden': ''")
</template>

<script>
import { mapGetters } from "vuex";
import RespBgVideo from "./RespBgVideo";
import RespImg from "./RespImg";
export default {
  name: "SliceBg",
  props: ["body", "rounded"],
  computed: {
    ...mapGetters(["aspectRatio"])
  },
  methods: {
    imgIsWiderAR(image) {
      const dims = image && image.dimensions;
      const elAR = this.$el && this.$el.offsetWidth / this.$el.offsetHeight;
      return dims && dims.width / dims.height > elAR;
    }
  },
  components: { RespBgVideo, RespImg }
};
</script>
