<template lang="pug">
.slice--full(:class="{ padded }")
  .relative(:style="{background: slice.primary.bg_color}", :class="{ 'rounded-lg': rounded }")

    //- (landing/intro space for scroll up)
    template(v-if="hasLandingIntroScrollSpace")
      //- change header title to first project
      observer.absolute.w-full.top-0(style="height:10px", :threshold=".1", @hidden="$emit('afterLanding')")
      //- landing scroll space
      landing-space

    //- (background media)
    .absolute.h-full.w-full.top-0.left-0(v-if="hasBg")
      //- sticky el
      slice-bg.sticky.w-full.h-screen.top-0.left-0(:body="slice.primary", :byHeight="aspectRatio < 16/9", @load="endLoading", @error="endLoading")

    //- (foreground layer items...)
    figure.relative.overflow-hidden(v-for="(item, i) in slice.items", :id="!preload && !i &&  `prj${prjNo}__slice${sliceNo}__content`", :class="{'landing-slice__content': isLanding, 'h-screen': item.height === '1x screen'}")
      .relative.w-full.h-full.overflow-hidden(:class="{ 'rounded-lg': slice.primary.margin }")
        //- ? svg animation
        template(v-if="item.animation_frame_rate && item.svg_graphic.url")
          svg-animation.w-full(:svgUrl="item.svg_graphic.url", :frameRate="item.animation_frame_rate", :size="svgAnimSize(item.height)")
        //- ? natural height
        template(v-else-if="item.height === 'natural'")
          img.block.w-full(:src="item.svg_graphic.url", :alt="item.svg_graphic.alt")

        figure.relative.overflow-hidden.w-full(v-if="lottieJson", :class="{'h-screen': item.height === '1x screen', 'grid mdlg-block place-items-center': item.height === '1x screen'}")
          lottie-animation.h-auto.mdlg-h-full.w-full.object-contain(:data="lottieJson", preserveAspectRatio="")

        //- ? screen height ("1x screen")
        template(v-else-if="!item.animation_frame_rate")
          img.absolute.overlay-px.object-contain.object-center(v-if="item.svg_graphic.url", :src="item.svg_graphic.url", :alt="item.svg_graphic.alt")
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SliceBg from "../../components/SliceBg";
import Observer from "../../components/IntersectionObserver";
import svgAnimation from "../../components/SVGAnimation";
import LandingSpace from "../../components/LandingSpace";
import LottieAnimation from "../../components/LottieAnimation.vue";
import get from "lodash/get";

export default {
  name: "Slice--Full",
  props: ["slice", "prjNo", "sliceNo", "preload", "isLanding"],
  data() {
    return {
      lastY: 0
    };
  },
  computed: {
    ...mapState(["intro", "loading"]),
    ...mapGetters(["aspectRatio"]),
    hasBg() {
      return (
        get(this.slice, "primary.bg_video_file.url") ||
        get(this.slice, "primary.bg_video") ||
        get(this.slice, "primary.bg_img.url")
      );
    },
    hasLandingIntroScrollSpace() {
      const isIntroAnimBST = this.$store.state.introSettings?.type === "bst";
      return isIntroAnimBST && this.isLanding && !this.preload;
    },
    lottieJson() {
      if (!this.slice || !this.slice.primary.lottie_file) return false;
      return this.slice.primary.lottie_file;
    },
    padded () {
      return !!this.slice?.primary?.margin
    },
    rounded () {
      return !!this.slice?.primary?.corners
    }
  },
  methods: {
    svgAnimSize(field) {
      return field === "natural" ? "w-auto" : "cover";
    },
    endLoading() {
      /** TODO - consider moving this logic into IntroCard where it's actually relevant ? **/

      // end preloading
      if (this.isPreload) {
        this.$store.commit("PRELOAD_END");
      }
      // end loading
      if (!this.isLanding || !this.loading) return;
      setTimeout(() => {
        this.$store.commit("END_LOADING");
        // setTimeout(() => this.$store.commit('END_INTRO'), 1000)
      }, 500);
    }
  },
  mounted() {
    if (!this.hasBg) this.endLoading();
  },
  components: { SliceBg, Observer, svgAnimation, LandingSpace, LottieAnimation }
};
</script>
