<template lang="pug">
.flex.justify-end(:style="style")
  resp-img(:src="item.image.url", :style="{ borderRadius: '4px' }")
</template>

<script>
import RespImg from "../../components/RespImg.vue";

export default {
  props: ["item", "width"],
  components: {
    RespImg
  },
  computed: {
    ratio() {
      const { width, height } = this.item.image.dimensions;
      return width / height;
    },
    style() {
      return {
        width: this.ratio > 1 ? `${this.width}px` : `${this.width * 0.8}px`
      };
    }
  }
};
</script>
